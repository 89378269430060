import React from 'react';
import { components } from 'outlinejs';

import { EpocaBaseContentView } from '../core/views';

export class PaymentConfirmed extends components.BaseComponent {
  render() {
    const { orderId, adminUrl } = this.props;

    let msg = this.i18n.gettext(
      "Per seguire lo stato di avanzamento dell'ordine n° {ORDER_ID} o annullarlo entro 2 ore dalla sua conferma accedi all' {RESERVED_AREA_LINK}."
    );
    msg = msg.replace('{ORDER_ID}', `<strong>${orderId}</strong>`);
    msg = msg.replace(
      '{RESERVED_AREA_LINK}',
      `<a href=${adminUrl}>
        <strong>${this.i18n.gettext('area riservata')}</strong>
      </a>`
    );

    return (
      <div className="thankyou_page">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="cart-main-title">
              {this.i18n.gettext('Complimenti per il tuo acquisto')}
            </h1>
            <p>
              {this.i18n.gettext(
                'Hai scelto la qualità Made in Italy che rende Album Epoca il punto di riferimento e la scelta per i migliori fotografi professionisti del mondo'
              )}
              <br />
              <span dangerouslySetInnerHTML={{ __html: msg }} />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export class PaymentDenied extends components.BaseComponent {
  render() {
    return (
      <div className="thankyou_page">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="cart-main-title">
              {this.i18n.gettext('Il pagamento non è andato a buon fine')}
            </h1>
            <p>
              {this.i18n.gettext(
                "Durante la procedura di pagamento si è verificato un problema che ha reso impossibile concludere la transazione e confermare l'ordine."
              )}
              .
              <br />
              {this.i18n.gettext(
                'Ti invitiamo a ripetere la procedura di acquisto assicurandoti che nessun importo è stato imputato.'
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Main ThankYou content View.
 *
 * NOTE: render method is defined in EpocaBaseContentView
 * */
export class ThankYouView extends EpocaBaseContentView {
  renderContent() {
    const { orderId, adminUrl, confirmed } = this.props;
    return (
      <div className="thankyou_page">
        <div className="row">
          {confirmed ? (
            <PaymentConfirmed orderId={orderId} adminUrl={adminUrl} />
          ) : (
            <PaymentDenied />
          )}
        </div>
      </div>
    );
  }
}
