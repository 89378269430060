import { conf } from 'outlinejs';
import { EpocaBaseController } from '../core/controllers';
import { ThankYouView } from './views';

export class ThankYouController extends EpocaBaseController {
  get view() {
    return ThankYouView;
  }

  get context() {
    return Object.assign(super.context, {
      orderId: this.orderId,
      confirmed: this.confirmed,
      adminUrl: conf.settings.ADMIN_AREA_URL,
      topMenuBarVisible: this.topMenuBarVisible
    });
  }

  async initContentProps(orderId) {
    await super.initContentProps();
    this.topMenuBarVisible = false;
    this.orderId = orderId;
    this.confirmed = orderId !== 'null';
  }

  async init(orderId) {
    await this.initContentProps(orderId);
    this.render(this.context);
  }
}
