import { routers, routing } from 'outlinejs';

import { ThankYouController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('thankyou:main', ThankYouController),
      ':orderGuid:': routing.url('thankyou:orderGuid', ThankYouController)
    };
  }
}
